import type { FlexProps as _FlexProps, SpacerProps as _FlexSpacerProps } from "@chakra-ui/react";
import { forwardRef } from "@chakra-ui/react";
import { Flex as _Flex, Spacer as _Spacer } from "@chakra-ui/layout";
export interface FlexProps extends _FlexProps {}

export interface FlexSpacerProps extends _FlexSpacerProps {}

export const Flex = forwardRef<FlexProps, "div">(({ ...props }, ref) => (
    <_Flex ref={ref} {...props} />
));

export const FlexSpacer = ({ ...props }: FlexSpacerProps) => <_Spacer {...props} />;
